import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import UpRight from "../svgs/up-right.svg"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [INLINES.HYPERLINK]: (node, children) => {
      const destination = node && node.data && node.data.uri
      return (
        <a href={destination} className="inline-link">
          {children}
        </a>
      )
    },
  },
}

const InfoPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulGeneralSettings(
        id: { eq: "a0d4505b-b555-551d-bad7-f565b4d40fe2" }
      ) {
        socialMediaAccounts {
          title
          url
        }
      }
      contentfulAboutPage(id: { eq: "5c17f582-0a17-504b-b807-39111a606122" }) {
        heading
        subheading
        content {
          json
        }
      }
    }
  `)
  const { heading, subheading, content } = data.contentfulAboutPage
  const { socialMediaAccounts } = data.contentfulGeneralSettings

  const aboutContent = documentToReactComponents(content.json, options)

  return (
    <Layout>
      <div className="vertical-line"></div>
      <SEO title="About" />
      <h1>{heading}</h1>
      <span>{subheading}</span>
      <div style={{ marginBottom: `var(--gutter)` }}>{aboutContent}</div>

      <ul className="social-media">
        <p>ONLINE:</p>
        {socialMediaAccounts.map(social => (
          <li key={social.title}>
            <a
              className="link"
              target="_blank"
              rel="noreferrer"
              href={social.url}
            >
              <span>{`${social.title} `} </span>
              <UpRight className="arrow" />
            </a>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default InfoPage
